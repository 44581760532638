import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import logo from "./logo.svg";
import "./App.css";

// Inisialisasi Google Analytics
ReactGA.initialize("G-MRM78YHGJ9"); // Ganti dengan Tracking ID Anda

function App() {
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const trackEvent = (category, action, label) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  return (
    <>
      {/* SEO Optimization */}
      <Helmet>
        <title>YourBrand - Solusi Digital Terbaik untuk Bisnis Anda</title>
        <meta name="description" content="YourBrand menyediakan layanan digital terbaik seperti Web Development, Mobile Apps, dan Digital Marketing untuk meningkatkan bisnis Anda." />
        <meta name="keywords" content="web development, mobile apps, digital marketing, bisnis digital, solusi digital" />
        <meta name="author" content="YourBrand Team" />
        <link rel="canonical" href="https://yourbrand.com/" />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-purple-50">
        {/* Navbar */}
        <nav className="backdrop-blur-lg bg-white/70 fixed w-full z-50 border-b border-gray-200/20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16 items-center">
              <div className="flex items-center gap-2">
                <img
                  src={logo}
                  className="h-8 w-8 animate-[spin_20s_linear_infinite]"
                  alt="YourBrand Logo"
                />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 text-xl font-bold">
                  YourBrand
                </span>
              </div>
              <div className="hidden md:flex items-center gap-8">
                <a href="#home" className="text-gray-700 hover:text-blue-600 transition-colors duration-300">Beranda</a>
                <a href="#layanan" className="text-gray-700 hover:text-blue-600 transition-colors duration-300">Layanan</a>
                <a href="#portfolio" className="text-gray-700 hover:text-blue-600 transition-colors duration-300">Portfolio</a>
                <button
                  onClick={() => trackEvent("CTA", "click", "Contact")}
                  className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
                >
                  Kontak
                </button>
              </div>
            </div>
          </div>
        </nav>

        {/* Hero Section */}
        <section id="home" className="pt-28 pb-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              <div className="space-y-8">
                <h1 className="text-4xl lg:text-6xl font-bold leading-tight">
                  Wujudkan Visi Digital
                  <span className="block text-blue-600">Bisnis Anda</span>
                </h1>
                <p className="text-lg text-gray-600">
                  Solusi digital terpadu untuk mengakselerasi pertumbuhan bisnis Anda di era digital
                </p>
                <div className="flex gap-4">
                  <button
                    onClick={() => trackEvent("CTA", "click", "Get Started")}
                    className="bg-blue-600 text-white px-8 py-4 rounded-full hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
                  >
                    Mulai Sekarang
                  </button>
                  <button
                    onClick={() => trackEvent("CTA", "click", "Learn More")}
                    className="border-2 border-blue-600 text-blue-600 px-8 py-4 rounded-full hover:bg-blue-50 transition-all duration-300"
                  >
                    Pelajari Lebih
                  </button>
                </div>
              </div>
              <div className="relative hidden lg:block">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-400 rounded-full filter blur-3xl opacity-20"></div>
                <img
                  src="/hero-image.png"
                  alt="Digital Solutions"
                  className="relative z-10 w-full"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="layanan" className="py-20 bg-gray-50/50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl lg:text-4xl font-bold text-center mb-16">
              Layanan Unggulan
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                { title: "Web Development", icon: "🌐" },
                { title: "Mobile Apps", icon: "📱" },
                { title: "Digital Marketing", icon: "📈" },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className="text-4xl mb-4">{feature.icon}</div>
                  <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                  <p className="text-gray-600">
                    Solusi {feature.title} yang disesuaikan dengan kebutuhan bisnis Anda
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl lg:text-4xl font-bold mb-8">
              Siap Memulai Proyek?
            </h2>
            <button
              onClick={() => trackEvent("CTA", "click", "Contact")}
              className="bg-blue-600 text-white px-12 py-4 rounded-full text-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
            >
              Hubungi Kami
            </button>
          </div>
        </section>

        {/* Footer */}
        <footer className="bg-gray-900 text-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid md:grid-cols-4 gap-8">
              <div className="space-y-4">
                <h4 className="text-xl font-bold">YourBrand</h4>
                <p className="text-gray-400">
                  Solusi digital terbaik untuk bisnis Anda
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
